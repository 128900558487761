

/* --- BASIC SETTINGS --- */

@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@300&family=Kalam:wght@700&family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

* {
  color: #e3f5f5;
  font-family: 'Open Sans', sans-serif;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

.app {
  background-color: #282b37;
  display: flex;
}


/* --- SIDE BAR --- */

.side-bar {
  background-color: #202125;
  border-right: solid 0.5px #6d017b;
  height: 100vh;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 0;

  box-shadow: rgba(255, 255, 255, 0.02) 10px 0 20px,
              rgba(255, 255, 255, 0.02) 14px 0 16px,
              rgba(255, 255, 255, 0.01) 8px 0 12px,
              rgba(255, 255, 255, 0.01) 4px 0 8px;

}

button {
  border: solid 0.5px rgba(255, 255, 255, 0.5);
  background-color: transparent;
  border-radius: 5px;
  padding: 10px;
  margin: 15px;
  cursor: pointer;
}

nav {
  border-top: solid 0.5px rgba(255, 255, 255, 0.5);
  padding: 10px;
  margin: 10px;
}

nav p {
  text-align: center;
}

.history {
  padding: 10px;
  margin: 10px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.history li {
  list-style-type: none;
  padding: 15px 0;
  cursor: pointer;
}


/* --- MAIN SECTION --- */

.main {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}


/* --- top section --- */

.top-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 0;
}

.liluAvatar {
  background-image: url(../liluPhotos/lilu_500x500.jpg);
  background-repeat: no-repeat;
  height: 250px;
  width: 250px;
  min-height: 250px;
  background-size: contain;
  margin-top: 15px;
  border-radius: 20px;

  box-shadow: rgba(0, 0, 0, 0.15) 5px 10px 10px,
              rgba(255, 255, 255, 0.02) -5px -10px 10px,

              rgba(0, 0, 0, 0.1) 7px 1px 7px,
              rgba(255, 255, 255, 0.02) -7px -1px 7px,

              rgba(0, 0, 0, 0.1) -2px 7px 7px,
              rgba(255, 255, 255, 0.02) 2px -7px 7px,

              rgba(0, 0, 0, 0.1) 2px 3px 3px,
              rgba(255, 255, 255, 0.02) -2px -3px 3px;

  border-top: solid 1px rgba(0, 0, 0, 0.2);
  border-left: solid 1px rgba(0, 0, 0, 0.2);
  border-right: solid 1px rgba(255, 255, 255, 0.05);
  border-bottom: solid 1px rgba(255, 255, 255, 0.05);
}

h1 {
  color: #f2d8fa;
}

.feed {
  overflow-y: auto;
  overflow-x: hidden;
  width: 90%;
  flex-shrink: 1;
  padding: 0;
  margin-top: 20;
  margin-bottom: 40px;
  border-radius: 20px 0 0 20px;
}

.feed li {
  display: flex;
  width: 100%;
  padding: 15px;
  margin: 0;
}

.feed li:nth-child(odd) {
  background-color: #383a45;
  border-radius: 20px 0 0 0;
  border-top: solid 0.5px #bf01c2;
  border-left: solid 0.5px #bf01c2;
  margin-top: 20px;
  box-shadow: rgba(255, 255, 255, 0.02) 0 -5px 3px 3px,
              rgba(255, 255, 255, 0.02) 0 -3px 2px 2px;
}

.feed li:nth-child(even) {
  background-color: #333540;
  border-radius: 0 0 0 20px;
  border-left: solid 0.5px #bf01c2;
  border-bottom: solid 0.5px #6632b4;
  margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 5px 3px 3px,
              rgba(0, 0, 0, 0.1) 0 3px 2px 2px;
}

.feed p {
  color: rgba(255, 255, 255, 0.85);
  font-size: 14px;
  text-align: left;
}

.feed li:nth-child(even) p {
  color: #f2d8fa;
}

.feed p.role {
  min-width: 100px;
}

/* --- bottom section --- */

.bottom-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input-container {
  position: relative;
  width: 80%;
  max-width: 650px;
}

input {
  width: 100%;
  border-top: solid 1px rgba(0, 0, 0, 0.2);
  border-left: solid 1px rgba(0, 0, 0, 0.2);
  border-right: solid 1px rgba(255, 255, 255, 0.05);
  border-bottom: solid 1px rgba(255, 255, 255, 0.05);
  background-color: rgba(255, 255, 255, 0.05);
  padding: 12px 40px 12px 15px;
  border-radius: 5px;
  font-size: 20px;
  box-shadow: rgba(0, 0, 0, 0.05) 0 20px 20px,
              rgba(255, 255, 255, 0.01) 0 -20px 20px,

              rgba(0, 0, 0, 0.05) 10px 1px 10px,
              rgba(255, 255, 255, 0.008) -10px -1px 10px,

              rgba(0, 0, 0, 0.05) 0 15px 15px,
              rgba(255, 255, 255, 0.01) 0 -15px 15px,

              rgba(0, 0, 0, 0.05) 0 5px 5px,
              rgba(255, 255, 255, 0.01) 0 -5px 5px;
}

input:focus {
  outline: none;
}

#submit {
  font-size: 20px;
  position: absolute;
  top: 13px;
  right: 13px;
  cursor: pointer;
}

.info {
  color: rgba(255, 255, 255, 0.5);
  font-size: 11px;
  padding: 10px;
}

.mobileInfo {
  display: none;
  color: rgba(255, 255, 255, 0.5);
  font-size: 11px;
  padding: 10px;
}


/* --- spinner waiting for fetch API --- */

.spinner {
  -webkit-animation: rotate 2s linear infinite;
          animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 19px;
  right: -7px;
  margin: -25px 0 0 -25px;
  width: 38px;
  height: 38px;
}
.spinner .path {
  stroke: rgb(242, 216, 250);
  stroke-linecap: round;
  -webkit-animation: dash 1.5s ease-in-out infinite;
          animation: dash 1.5s ease-in-out infinite;
}

@-webkit-keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}



/* ^^^^--- the base is 1920px or above (1920x1080<) extra large screens, TV ---^^^^ */



/* vvvv----------------------------- MEDIA QUERIES -----------------------------vvvv */



/* vvvv---- 1280px-1919px (1280x720) large screens ----vvvv */

@media only screen and (min-width: 1280px) and (max-width: 1919px){


/* --- SIDE BAR --- */

nav p {
  font-size: 0.9em;
}

.history li {
  padding: 12px 0;
  font-size: 0.9em;
}


/* --- MAIN SECTION --- */

/* --- top section --- */

.liluAvatar {
  background-image: url(../liluPhotos/lilu_200x200.jpg);
  height: 200px;
  width: 200px;
  min-height: 200px;
}

h1 {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.feed {
  margin-top: 0;
  margin-bottom: 30px;
}

.feed li {
  padding: 0 15px;
}

.feed p {
  font-size: 13px;
}

.feed p.role {
  min-width: 80px;
}


/* --- bottom section --- */

input {
  padding: 10px 40px 10px 15px;
  font-size: 16px;
}

#submit {
  font-size: 20px;
  position: absolute;
  top: 8px;
  right: 13px;
  cursor: pointer;
}


/* --- spinner waiting for fetch API --- */

.spinner {
  top: 23px;
  right: -7px;
  margin: -25px 0 0 -25px;
  width: 32px;
  height: 32px;
}

}



/* vvvv---- 1024px-1279px (1024x768) small screens ----vvvv */

@media only screen and (min-width: 1024px) and (max-width: 1279px){


  /* --- SIDE BAR --- */

nav p {
  font-size: 0.9em;
}

.history li {
  padding: 12px 0;
  font-size: 0.9em;
}


/* --- MAIN SECTION --- */

/* --- top section --- */

.liluAvatar {
  background-image: url(../liluPhotos/lilu_200x200.jpg);
  height: 200px;
  width: 200px;
  min-height: 200px;
}

h1 {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.feed {
  margin-top: 0;
  margin-bottom: 30px;
}

.feed li {
  padding: 0 15px;
}

.feed p {
  font-size: 13px;
}

.feed p.role {
  min-width: 80px;
}


/* --- bottom section --- */

input {
  padding: 10px 40px 10px 15px;
  font-size: 16px;
}

#submit {
  font-size: 20px;
  position: absolute;
  top: 8px;
  right: 13px;
  cursor: pointer;
}


/* --- spinner waiting for fetch API --- */

.spinner {
  top: 23px;
  right: -7px;
  margin: -25px 0 0 -25px;
  width: 32px;
  height: 32px;
}

}



/* vvvv---- 768px-1023px (768x1080) tablet, iPad screens ----vvvv */

@media only screen and (min-width: 768px) and (max-width: 1023px){


  /* --- SIDE BAR --- */

  .side-bar {
    width: 260px;
  }

  nav p {
    font-size: 0.9em;
  }
  
  .history li {
    padding: 12px 0;
    font-size: 0.9em;
  }
  
  
  /* --- MAIN SECTION --- */
  
  /* --- top section --- */
    
  .liluAvatar {
    background-image: url(../liluPhotos/lilu_200x200.jpg);
    height: 200px;
    width: 200px;
    min-height: 200px;
  }
  
  h1 {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 15px;
  }
  
  .feed {
    margin-top: 0;
    margin-bottom: 30px;
  }
  
  .feed li {
    padding: 0 15px;
  }

  .feed p {
    font-size: 13px;
  }

  .feed p.role {
    min-width: 80px;
  }
  
  
  /* --- bottom section --- */
  
  .input-container {
    width: 70%;
    max-width: 600px;
  }

  input {
    padding: 10px 40px 10px 15px;
    font-size: 16px;
  }
  
  #submit {
    font-size: 20px;
    position: absolute;
    top: 8px;
    right: 13px;
    cursor: pointer;
  }
  
  
  /* --- spinner waiting for fetch API --- */
  
  .spinner {
    top: 23px;
    right: -7px;
    margin: -25px 0 0 -25px;
    width: 32px;
    height: 32px;
  }

}



/* vvvv---- max767px (375x667) mobile screens ----vvvv */

@media only screen  and (max-width: 767px){


  /* --- SIDE BAR --- */

  .side-bar {
    display: none;
  }
  
  
  /* --- MAIN SECTION --- */
  
  /* --- top section --- */
    
  .liluAvatar {
    background-image: url(../liluPhotos/lilu_150x150.jpg);
    height: 150px;
    width: 150px;
    min-height: 150px;
  }
  
  h1 {
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .feed {
    margin-top: 0;
    margin-bottom: 20px;
  }
  
  .feed li {
    padding: 0 10px 0 15px;
  }

  .feed p {
    font-size: 13px;
  }

  .feed p.role {
    min-width: 70px;
  }
  
  
  /* --- bottom section --- */
  
  .input-container {
    width: 70%;
    max-width: 600px;
  }

  input {
    padding: 10px 40px 10px 15px;
    font-size: 13px;
  }
  
  #submit {
    font-size: 18px;
    position: absolute;
    top: 8px;
    right: 13px;
    cursor: pointer;
  }

  .info {
    padding: 10px 10px 0 10px;
    margin-bottom: 0;
  }
  
  .mobileInfo {
    display: block;
    padding: 0 10px 10px 10px;
    margin-top: 0;
  }

  
  /* --- spinner waiting for fetch API --- */
  
  .spinner {
    top: 21px;
    right: -9px;
    margin: -25px 0 0 -25px;
    width: 32px;
    height: 32px;
  }

}



/* vvvv----  mobile screens landscape ----vvvv */

@media only screen  and (max-height: 550px){


  /* --- SIDE BAR --- */

  .side-bar {
    display: none;
  }
  
  
  /* --- MAIN SECTION --- */
  
  /* --- top section --- */
    
  .liluAvatar {
    background-image: url(../liluPhotos/lilu_150x150.jpg);
    height: 50px;
    width: 50px;
    min-height: 50px;
  }
  
  h1 {
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
  .feed {
    margin-top: 0;
    margin-bottom: 20px;
  }
  
  .feed li {
    padding: 0 10px 0 15px;
  }

  .feed p {
    font-size: 13px;
  }

  .feed p.role {
    min-width: 70px;
  }
  
  
  /* --- bottom section --- */
  
  .input-container {
    width: 70%;
    max-width: 600px;
  }

  input {
    padding: 10px 40px 10px 15px;
    font-size: 13px;
  }
  
  #submit {
    font-size: 18px;
    position: absolute;
    top: 8px;
    right: 13px;
    cursor: pointer;
  }

  .info {
    padding: 10px 10px 0 10px;
    margin-bottom: 0;
  }
  
  .mobileInfo {
    display: block;
    padding: 0 10px 10px 10px;
    margin-top: 0;
  }

  
  /* --- spinner waiting for fetch API --- */
  
  .spinner {
    top: 21px;
    right: -9px;
    margin: -25px 0 0 -25px;
    width: 32px;
    height: 32px;
  }

}
